"use client"; // Error components must be Client Components

import { useEffect } from "react";

import { useRouter } from "next/navigation";

import { Button } from "@/components/inputs";
import { ErrorPage } from "@/components/template/ErrorPage";
import { sendError } from "@/utils/sentry";

interface ErrorProps {
  error?: Error;
  reset?: () => void;
}

export default function Error({ error, reset }: ErrorProps) {
  const router = useRouter();

  useEffect(() => {
    if (error) {
      sendError(error);
    }
  }, [error]);

  return (
    <ErrorPage
      actions={
        <>
          <Button variants="outlined" rounded onClick={() => router.push("/")}>
            ホーム画面に戻る
          </Button>
          {reset && (
            <Button variants="yellow" rounded onClick={reset}>
              リトライ
            </Button>
          )}
        </>
      }
    />
  );
}
