"use client";

import { Column, Row } from "@/components/containers";
import { TheFooterJP, TheHeaderJP } from "@/components/layouts";

import styles from "./ErrorPage.module.scss";

interface ErrorPageProps {
  title?: string;
  message?: string;
  actions: React.ReactNode;
}

export function ErrorPage({
  title = "申し訳ございません。",
  message = "予期せぬエラーが発生しました。",
  actions,
}: ErrorPageProps) {
  return (
    <Column>
      <TheHeaderJP />
      <Column className={styles.container}>
        <Column className={styles.card}>
          <Column className={styles.titlesContainer}>
            <h3 className={styles.title}>{title}</h3>
            <h3 className={styles.title}>{message}</h3>
          </Column>
          <Row className={styles.actions}>{actions}</Row>
        </Column>
      </Column>
      <TheFooterJP />
    </Column>
  );
}
